import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Tooltip, Form } from 'antd';
import IconEdit from '../../components/Icons/IconEdit';
import EditingDrawer from '../components/EditingDrawer';
import RequestModel from '../../apis/models/RequestModel';
import { message } from '../../components/Common/Message';
import _forIn from 'lodash/forIn';
import { InputType } from '@utils/enums/InputType';
import { productOptionTypesService } from '@services/productOptionTypesService';

const BtnEdit = memo(({ rowData, onSuccess, tooltip, typeOptions, ...ownProps }) => {
   const [isDrawerOpen, setIsDrawerOpen] = useState(false);
   const [form] = Form.useForm();
   const [submitting, setSubmitting] = useState(false);

   const showDrawer = useCallback(() => {
      if (rowData) {
         const valueObj = {};
         _forIn(rowData, (value, key) => {
            valueObj[key] = value;
         });
         form.setFieldsValue(valueObj);
      }
      setIsDrawerOpen(true);
   }, [form, rowData]);

   const onClose = useCallback(() => {
      setIsDrawerOpen(false);
   }, []);

   useEffect(
      () => {
         if (rowData) {
            form.setFieldsValue({
               name: rowData?.name || '',
               description: rowData?.description || '',
               type_uid: rowData?.type?.uid || '',
            });
         }
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [rowData]
   );

   const onSubmit = useCallback(
      async (values) => {
         await setSubmitting(true);
         const body = {
            name: values?.name || '',
            value: values?.name || '',
            description: values?.description || '',
         };
         if (!rowData?.used) {
            body.type_uid = values?.type_uid || '';
         }
         const requestBody = new RequestModel(body);
         let responseData;
         // is update
         if (rowData?.uid) {
            responseData = await productOptionTypesService.updateOption(
               rowData.uid,
               requestBody
            );
         }
         // is create
         else {
            responseData = await productOptionTypesService.createOption(requestBody);
         }
         if (responseData?.isSuccess) {
            productOptionTypesService.getOptionTypes({
               page: 1,
               page_size: 1000,
               order_by: 'updated_at',
               sort_type: 'DESC',
            });
            onSuccess();
            message.success(
               rowData
                  ? `You successfully updated option data.`
                  : `Create new option successfully.`
            );
            onClose();
            form.resetFields();
         } else {
            message.error(responseData.message);
         }

         setSubmitting(false);
      },
      [onSuccess, rowData, form, onClose]
   );

   const tooltipProps = !tooltip ? { visible: false } : {};

   const formDefinitions = useMemo(() => {
      return [
         {
            name: 'name',
            label: 'Name',
            placeholder: 'Option name',
            rules: [
               {
                  required: true,
                  message: 'Please input your Option name.',
               },
            ],
            isRequired: true,
         },
         {
            name: 'description',
            label: 'Description',
            type: InputType.TEXTAREA,
            placeholder: 'Description',
         },
         {
            name: 'type_uid',
            label: 'Type',
            placeholder: 'Option type',
            type: InputType.SELECT,
            rules: [
               {
                  required: true,
                  message: 'Please select option Type.',
               },
            ],
            options: typeOptions,
            disabled: rowData?.used,
            isRequired: true,
         },
      ];
   }, [typeOptions, rowData]);

   return (
      <>
         <Tooltip
            {...tooltipProps}
            placement="bottom"
            title={tooltip || ''}
            color="black"
         >
            <button className={ownProps.className} onClick={showDrawer}>
               {ownProps.children}
            </button>
         </Tooltip>
         <EditingDrawer
            title={rowData ? 'Edit Option' : 'Create a new Option'}
            isOpen={isDrawerOpen}
            form={form}
            formDefinitions={formDefinitions}
            submitting={submitting}
            currentUid={rowData?.uid}
            onClose={onClose}
            onSubmit={onSubmit}
         />
      </>
   );
});

export default BtnEdit;
