import { FETCH_PRO_OPTION_TYPES } from '../constants/actions';
import { dispatch } from '@store/store';
import Request from './utils/Request';
import { adminService } from './adminService';
import { fetch } from '@apis/ServiceController';
import { apiConstant } from '@apis/constant';
import { tokenUtil } from '@utils/token';

const getOptionTypes = async (body) => {
   return await dispatch(
      Request.post(FETCH_PRO_OPTION_TYPES, {
         data: body,
      })
   );
};

const getOptionTypeMatrix = async (body) => {
   return fetch({
      method: 'post',
      url: apiConstant.GET_OPTION_TYPE_MATRIX,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      errorHandler: adminService.handleError,
   });
};

const createOptionType = async (body) => {
   return fetch({
      method: 'post',
      url: apiConstant.CREATE_OPTION_TYPE,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      errorHandler: adminService.handleError,
   });
};

const updateOptionType = async (body, code) => {
   return fetch({
      method: 'put',
      url: apiConstant.UPDATE_OPTION_TYPE,
      data: body,
      params: { code },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      errorHandler: adminService.handleError,
   });
};

const getOptionMatrix = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.GET_OPTION_MATRIX,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const createOption = async (body, cancelToken) => {
   return fetch({
      method: 'post',
      url: apiConstant.CREATE_OPTION,
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const updateOption = async (uid, body, cancelToken) => {
   return fetch({
      method: 'put',
      url: apiConstant.UPDATE_OPTION,
      params: {
         uid,
      },
      data: body,
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

const deleteOptions = async (uids, cancelToken) => {
   return fetch({
      method: 'delete',
      url: apiConstant.DELETE_OPTION,
      data: {
         uids,
      },
      headers: {
         'Content-Type': 'application/json',
         Authorization: tokenUtil.getToken(),
      },
      cancelToken,
      errorHandler: adminService.handleError,
   });
};

export const productOptionTypesService = {
   getOptionTypes,
   getOptionTypeMatrix,
   createOptionType,
   updateOptionType,
   getOptionMatrix,
   createOption,
   updateOption,
   deleteOptions,
};
